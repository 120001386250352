<template>
  <div>

    <v-card flat outlined class="pa-1"
      v-for="payType in payTypes" :key="payType.type">
      <div class="d-flex justify-space-between">
        <!-- Label and verification status -->
        <div>
          <v-icon :class="getClassByStatus(payType.status)">mdi-circle</v-icon>
          
          <span>{{ payType.label }}</span>

          <span :class="getClassByStatus(payType.status)">&nbsp;&nbsp;&nbsp;{{ payType.status | toStatusLiteral }}</span>
        </div>
        
        <!-- Action buttons -->
        <div>
          <v-btn icon text small @click="fetchStatus()"><v-icon>mdi-refresh</v-icon></v-btn>
          <v-btn depressed rounded small color="primary" @click="requestVerify(payType.type)">開始驗收 - 測試付款</v-btn>
          <v-btn depressed rounded small color="warning" @click="requestRefund(payType.type)">逆流程 - 測試退款</v-btn>
          <v-btn depressed rounded small color="success" @click="updateStatus(payType.type, true)">確認驗收完成</v-btn>          
          <v-btn depressed rounded small color="error" @click="requestReset(payType.type)">取消驗收</v-btn>
        </div>
      </div>
    </v-card>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MerchantVerificationView',
  props: {
    merchantId: String
  },

  mounted() {
    this.fetchStatus();
  },

  methods: {

    fetchStatus() {
      axios.get(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/verify-status`
      )
      .then(response => {
        let status = response.data;
        this.payTypes = [ ];
        for (let s in status) {
          this.payTypes.push({
            type: s,
            label: {
              'apple': 'Apple Pay',
              'google': 'Google Pay',
              'credit': '信用卡',
              'line': 'LINE Pay',
              'easywallet': '悠遊付',
              'jko': '街口支付',
              'icashpay': 'icash Pay',
              'pxpayplus': '全支付',
              'pluspay': '全盈支付',
              'taiwanpay': '台灣Pay'
            }[ s ],
            status: status[ s ]
          })
        }
        // console.log(this.payTypes);
      })
      .catch(err => {
        console.error(`[MchVerificationView] fetchStatus: error`, err);
      });
    },

    requestVerify(_payType) {
      let pt = this.payTypes.find(pt => pt.type === _payType);
      if (!pt) {
        console.error(`[MchVerificationView] requestVerify: no such pay type`, _payType);
        return;
      }
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/request-verify`,
        { paymentType: _payType }
      )
      .then(response => {
        if (response.data.order) {
          pt.status = 'pay-pending';
        
          // Obtain test payment URL and prompt to pay.
          let ptLiteral = {
            'apple': 'Apple Pay',
            'google': 'Google Pay',
            'credit': '信用卡',
            'line': 'LINE Pay',
            'easywallet': '悠遊付',
            'jko': '街口支付',
            'icashpay': 'icash Pay',
            'pxpayplus': '全支付',
            'pluspay': '全盈支付',
            'taiwanpay': '台灣Pay'
          }[ pt.type ];
          alert(`${ptLiteral} 測試付款連結已產生，將前往測試支付。`);
          window.open(response.data?.order.payment_url, '_blank');
        }
        else {
          this.$notify({
            type: 'error',
            text: '無法產生測試付款連結，可能仍有正在進行中的驗收流程。'
          });
        }
      })
      .catch(err => {
        console.error(`[MchVerificationView] requestVerify: error`, err.response.data);
      });
    },

    requestRefund(_payType) {
      let pt = this.payTypes.find(pt => pt.type === _payType);
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/refund-verify`,
        { paymentType: _payType }
      )
      .then(response => {
        pt.status = 'payment-verified';
        this.$notify({
          type: 'warning',
          text: '逆流程驗收已執行，請稍後檢查商家email有無收到逆流程驗收確認信。'
        });
      })
      .catch(err => {
        console.error(`[MchVerificationView] requestRefund: error`, err.response.data);
        pt.status = 'error';
        this.$notify({
          type: 'error',
          text: '逆流程驗收已執行但回報失敗：退款失敗。'
        });
      });
    },

    requestReset(_payType) {
      let pt = this.payTypes.find(pt => pt.type === _payType);
      if (!pt) {
        console.error(`[MchVerificationView] requestReset: no such pay type`, _payType);
        return;
      }
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/reset-verify`,
        { paymentType: _payType }
      )
      .then(response => {
        pt.status = 'not-verified';
      })
      .catch(err => {
        console.error(`[MchVerificationView] requestReset: error`, err.response.data);
      });
    },

    updateStatus(_payType, _isDone) {
      let pt = this.payTypes.find(pt => pt.type === _payType);
      if (!pt) {
        console.error(`[MchVerificationView] updateStatus: no such pay type`, _payType);
        return;
      }
      axios.put(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.merchantId}/verify-status`,
        {
          paymentType: _payType, 
          isDone: _isDone
        }
      )
      .then(response => {
        // let status = response.data?.status;
        // if (status === true) {
        //   pt.status = 'verified';
        // }
        this.fetchStatus();
        //TODO: verification error status?
      })
      .catch(err => {
        console.error(`[MchVerificationView] updateStatus: error`, err.response.data);
      });
    },

    getClassByStatus(s) {
      return {
        'grey--text': s === 'not-verified',
        'orange--text': [ 'pay-pending', 'refund-pending', 'payment-verified' ].includes(s),
        // 'yellow--text': s === 'paid',
        'green--text': s === 'verified',
        'red--text': s === 'error'
      };
    }
  },

  data() {
    return {
      status: { },
      payTypes: [
        {
          type: 'apple',
          label: 'Apple Pay',
          status: 'not-verified'
        },
        {
          type: 'google',
          label: 'Google Pay',
          status: 'not-verified'
        },
        {
          type: 'credit',
          label: '信用卡',
          status: 'not-verified'
        },
        {
          type: 'line',
          label: 'LINE Pay',
          status: 'not-verified'
        },
        {
          type: 'easywallet',
          label: '悠遊付',
          status: 'not-verified'
        },
        {
          type: 'jko',
          label: '街口支付',
          status: 'not-verified'
        },
        {
          type: 'pxpayplus',
          label: '全支付',
          status: 'not-verified'
        },
        {
          type: 'pluspay',
          label: '全盈支付',
          status: 'not-verified'
        },
        {
          type: 'taiwanpay',
          label: '台灣Pay',
          status: 'not-verified'
        }
      ],
    };
  },

  computed: {

  },

  watch: {
    merchantId(v) {
      console.log('watch.merchantId', v);
      this.fetchStatus();
    }
  },

  filters: {
    toStatusLiteral(s) {
      return {
        'not-verified': '未驗收',
        'pay-pending': '支付待完成',
        'paid': '支付完成',
        'refund-pending': '逆流程（退款）待確認',
        'payment-verified': '支付驗收完成',
        'verified': '聯絡資訊驗收完成',
        'error': '錯誤',
      }[ s ];
    }
  }
}
</script>