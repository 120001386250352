<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card>
      <MerchantSettingsView
        :merchant-id="merchantId" :merchant-name="merchantName">
      </MerchantSettingsView>
    </v-card>
  </v-container>
</template>

<script>
import MerchantSettingsView from '@/views/dashboard/subviews/MerchantSettingsView';

export default {
  components: {
    MerchantSettingsView
  },

  computed: {
    merchantId() {
      console.warn(this.$store.getters.user);
      if (this.$store.getters.user['merchant'])
        return this.$store.getters.user['merchant']['id'];
    },
    merchantName() {
      if (this.$store.getters.user['merchant'])
        return this.$store.getters.user['merchant']['name'];
    }
  }
}
</script>